export enum Screen {
  CatalogRules = 'catalog_rules',
  Checkout = 'checkout',
  CheckoutPaymentErrorModal = 'generic_error_modal',
  Bundling = 'bundling',
  BundleSummary = 'bundle_summary',
  Brands = 'brands',
  ExpiredCardModal = 'expired_card_modal',
  CreditCardExpired = 'credit_card_expired',
  DeliveryInstructions = 'delivery_instructions',
  Item = 'item',
  MessageReply = 'message_reply',
  MessageList = 'message_list',
  MessageNew = 'message_New',
  PhoneVerification = 'verification_prompt_phone',
  PhoneVerificationCheck = 'verification_prompt_phone_check',
  TwoFactorAuthentication = 'two_factor_authentication',
  ItemUpload = 'upload_item',
  ItemEdit = 'edit_item',
  ItemDraft = 'edit_draft',
  ItemReservation = 'item_reservation',
  ItemMarkAsSold = 'item_mark_as_sold',
  ListerBadgeInfo = 'lister_badge_info',
  ListAnotherItem = 'list_another_item',
  ReplierBadgeInfo = 'replier_badge_info',
  UserProfile = 'user_profile',
  UserAbout = 'user_info',
  CurrentUserProfile = 'current_user_profile',
  TrackShipmentView = 'track_shipment_view',
  OrderDetails = 'order_details',
  OldCatalogRules = 'old_catalog_rules',
  PaymentOptions = 'payment_options',
  RequestToReturn = 'request_to_return',
  DropOffPointInformation = 'dropoff_point_information',
  ComplaintProof = 'complaint_proof',
  ShippingSettings = 'shipping_settings',
  SubscriptionSettings = 'subscription_settings',
  PaymentSettings = 'payment_settings',
  PrivacySettings = 'privacy_settings',
  ProfileSettings = 'profile_settings',
  PoliciesSettings = 'policies_settings',
  KycMain = 'identity_confirmation',
  KycAddressModal = 'billing_address_update',
  KybEducation = 'kyb_education',
  KybForm = 'kyb_form',
  KycDocumentDeleteModal = 'delete_documents_modal',
  KycClosingModal = 'closing_modal',
  KycStatus = 'status_screen',
  KycConfirmIdentityModalPayout = 'confirm_identity_modal_payout',
  KycConfirmIdentityModalEscrow = 'confirm_identity_modal_escrow',
  KycBankStatementEducation = 'kyc_bank_statement_education',
  KycAddressProofEducation = 'address_proof_education',
  SddMain = 'identity_confirmation_simplified',
  SddEducation = 'sdd_education',
  SddForm = 'sdd_form',
  SddMethods = 'sdd_verification_methods',
  SddStatus = 'sdd_status_screen',
  SddPayoutModal = 'sdd_payout_confirm_identity_modal',
  MergeAnnouncement = 'announcement',
  MergeAnnouncementTarget = 'announcement_target',
  MergeGoToOldPortal = 'go_to_old_portal',
  GetShippingLabel = 'get_shipping_label',
  DonationsOverview = 'donations_overview',
  FundraiserOverview = 'donations_overview',
  DonationsSetup = 'donations_set_up',
  FundraiserSetup = 'donations_set_up',
  FavoriteItemList = 'favorite_item_list',
  Catalog = 'catalog',
  Welcome = 'welcome',
  Balance = 'balance',
  OrdersList = 'orders_list',
  RefundStatus = 'refund_status',
  PickupPointMap = 'dropoff_point_map',
  PickupPointList = 'dropoff_point_list',
  HomeDeliverySelection = 'home_delivery_selection',
  PaymentsAccount = 'payments_account',
  PaymentsAccountInTransaction = 'payments_account_in_transaction',
  PaymentAccountRestricted = 'payments_account_restricted',
  PepDetails = 'pep_details',
  PepFamilyDetails = 'pep_family_details',
  PepSelection = 'pep_selection',
  PepStatusSection = 'pep_status_section',
  BillingAddressUpdate = 'billing_address_update',
  DropoffTypeSelection = 'dropoff_type_selection',
  BankAccount = 'bank_account',
  BuyerInvoiceDetails = 'b2c_buyer_invoice_details',
  CreditCardAdd = 'credit_card_add',
  CardSecurityCodeRequest = 'card_security_code_request',
  SaveCreditCardReminder = 'save_credit_card_reminder',
  TransactionCancellationReasons = 'transaction_cancellation_reasons',
  ContactForm = 'contact_form',
  LiveChatForm = 'live_chat_form',
  ReturnAddress = 'return_address',
  EprSettings = 'epr_settings',
  RegistrationBusinessDetails = 'registration_business_details',
  RegistrationBusinessRepresentative = 'registration_business_representative',
  RegistrationBusinessAddress = 'registration_business_address',
  RegistrationBusinessProfile = 'registration_business_profile',
  WalletConversion = 'wallet_conversion',
  WalletConversionFailed = 'wallet_conversion_failed',
  BusinessHomepage = 'business_homepage',
  DigitalLabel = 'digital_label',
  ReferralHowItWorks = 'referral_info_items',
  ReferralInvite = 'referral_invite',
  ReferralsFriendsList = 'registered_referrals_list',
  ReferralsRewardsList = 'referrals_rewards_list',
  ReferralProgramOff = 'referral_info_items_program_off',
  ContactDetails = 'contact_details',
  SellerContactDetails = 'seller_contact_details',
  ConfirmEverythingIsOkModal = 'confirm_everything_is_ok_modal',
  InsufficientBalanceModal = 'insufficient_balance_modal',
  KycEducation = 'kyc_education',
  EscrowOnboarding = 'escrow_onboarding',
  DocumentUploadTips = 'document_upload_tips',
  ProductFeedbackForm = 'product_feedback_form',
  MergeMissingInformation = 'merge_missing_information',
  FillZipCodeAfterListing = 'fill_zip_code_after_listing',
  CrmInAppMessage = 'crm_in_app_message',
  SellerPolicies = 'seller_policies',
  EditBusinessAddress = 'edit_business_address',
  EditReturnAddress = 'edit_return_address',
  PushUpOrderConfirmation = 'push_up_order_confirm',
  PushUpOrderReview = 'push_up_order_review',
  PushUpTeaser = 'push_up_teaser',
  ClosetPromotionOrderConfirmation = 'closet_promo_confirm',
  FreeReturnLabelModal = 'free_return_label_modal',
  ClosetPromotionOrderReview = 'closet_promo_prepare',
  EscrowFeeEducationModal = 'escrow_fee_education',
  LabelTypeSelection = 'label_type_selection',
  DirectDonation = 'direct_donation',
  PriceBreakdownModal = 'price_breakdown_modal',
  DirectDonationOrderConfirmation = 'direct_donation_order_confirm',
  ContactSupport = 'contact_support',
  ImprovementTips = 'improvement_tips',
  ReturnOrder = 'return_order',
  RequestItemReturn = 'request_item_return',
  Unknown = 'unknown',
  ItemUploadFeedbackForm = 'item_upload_feedback_form',
  SizesModal = 'sizes_modal',
  CatalogFilters = 'search_filter',
  MobileCatalogFilters = 'search_filter',
  MobileCatalogFilter = 'filter_catalog_selection',
  BuyerOriginatingOffer = 'buyer_originating_offer',
  InfoAboutMeasurements = 'info_about_measurements',
  FastShippingEducation = 'fast_shipping_education',
  NewsFeed = 'news_feed',
  DynamicFilter = 'dynamic_filter',
  MandatoryEmailVerification = 'mand_email_verification',
  MandatoryEmailVerificationStart = 'mand_email_verification_start',
  MandatoryEmailVerificationEnterCode = 'mand_email_verification_enter_code',
  MandatoryEmailVerificationResendCode = 'mand_email_verification_resend_code',
  MandatoryEmailVerificationSuccess = 'mand_email_verification_success',
  BlikCodeRequest = 'blik_code_request',
  MultiplePushUp = 'multiple_push_up',
  ConversationList = 'message_list',
  ConversationNew = 'message_new',
  ConversationReply = 'message_reply',
  ConversationEducation = 'first_sale_education_screen',
  ConversationHAOVEducation = 'haov_first_sale_education_screen',
  About = 'about',
  HowItWorks = 'how_it_works',
  VasOptionSelectionDrawer = 'vas_option_selection_drawer',
  UserItemsManagement = 'user_items_management',
  HelpRoot = 'help_root',
  NotificationList = 'notification_list',
  TermsAndConditions = 'terms_view',
  TermsAndConditionsForced = 'force_accept_terms_and_conditions',
  ProfileTab = 'profile_tab',
  PackageSizeEducation = 'package_size_education',
  PhotoEditing = 'photo_editing',
  PhotoTips = 'photo_tips',
  AdminAlerts = 'admin_alerts',
  HelpCenterTransaction = 'help_center_transaction',
  HelpCenterFaq = 'help_center_faq',
  HelpCenterSearch = 'help_center_search',
  HelpCenterRecentTransactions = 'help_center_recent_transactions',
  TaxpayersCountryOfTaxResidencyModal = 'taxpayers_country_selection',
  TaxpayersEducationModal = 'taxpayers_education',
  TaxpayersForm = 'taxpayers_info_submission_form',
  TaxpayersTaxIdentificationNumberEducationModal = 'taxpayers_tin_education',
  TaxpayersStaticPlacement = 'taxpayers_info_display',
  DeleteAccount = 'delete_account',
  DeleteAccountSuccess = 'delete_account_success',
  FeedSizeCategories = 'feed_size_categories',
  BrowseAllBrands = 'browse_all_brands',
  UserMigration = 'user_migration',
  ReturnLabeOrderConfirm = 'return_label_order_confirm',
  ReferralCodeShare = 'referral_code_share',
  ChangeForgotPassword = 'change_forgot_password',
  ChangePassword = 'change_password',
  ViewIssueDetails = 'view_issue_details',
  IntellectualPropertyInfringement = 'intellectual_property_infringement',
  NoticeAndActionForm = 'notice_and_action_form',
  Homepage = 'homepage',
  ExtraServicePendingPaymentModal = 'payment_pending_modal',
  HelpCenterHome = 'help_center_home',
  Followers = 'current_user_followers',
  Following = 'current_user_following',
  UserSearch = 'users',
  Advertising = 'advertising',
  Bank2FAVerification = 'bank_2fa_verification',
  DeskPostTicketSurveyForm = 'desk_post_ticket_survey_form',
  ChangeEmail = 'change_email',
  ConfirmEmail = 'confirm_email',
  FullNameConfirmation = 'full_name_confirmation',
  ProcessingListingStatusModal = 'processing_listing_modal',
  CreatePhoneNumber = 'create_phone_number',
  ChangePhoneNumber = 'change_phone_number',
  EmailRegister = 'email_register',
  Banned = 'banned',
  SecuritySessions = 'security_sessions',
  SecurityAuthentication = 'security_authentication',
  SecuritySettings = 'security_settings',
  AccountSettings = 'account_settings',
  ResetPassword = 'password_change',
  Authentication = 'authentication',
  RequestDataExport = 'request_data_export',
  EmailVerification = 'email_verification',
  SelectLoginType = 'select_login_type',
  AuthSelectTypeLogin = 'auth_select_type_login',
  AuthSelectTypeRegister = 'auth_select_type_register',
  AuthEmailLogin = 'auth_email_login',
  AuthEmailRegister = 'auth_email_register',
  AuthResetPassword = 'auth_reset_password',
  AuthTwoFactorLogin = 'auth_two_factor_login',
  AuthFacebookRegister = 'auth_facebook_register',
  AuthGoogleRegister = 'auth_google_register',
  AuthAppleRegister = 'auth_apple_register',
  AuthPasswordVerification = 'auth_confirm_password',
  UserVerification = 'user_verification',
  WalletSetup = 'payments_account',
  InfoBoard = 'info_board',
  InfoPost = 'info_post',
  ClosetPromotionStatistics = 'cp_stats',
  NewPayout = 'new_payout',
  PayoutStatus = 'payout_status',
  WalletInvoices = 'wallet_invoices',
  IncomeReports = 'income_reports',
  NotificationLandings = 'notification_landings',
  FaqEntry = 'faq_entry',
  PrivacyPolicy = 'privacy_policy',
  WalletHistoryOverview = 'wallet_history_overview',
  WalletHistory = 'wallet_history',
  CookiePolicy = 'cookie_policy',
  CommonText = 'common_text',
  FeedbackEdit = 'feedback_edit',
  DropOffPointMap = 'drop_off_point_map',
  PhotosMetadataNote = 'photos_metadata_note',
  Appeal = 'appeal',
  SellerDropOffMap = 'seller_drop_off_map',
  DsaNoticeAppeal = 'dsa_notice_appeal',
  DsaNtdAppeal = 'dsa_ntd_appeal',
  PrivateBetaTermsOfUse = 'private_beta_terms_of_use',
  ProTermsAndConditions = 'pro_terms_and_conditions',
  ProTermsOfUse = 'pro_terms_of_use',
  ProIntegrations = 'pro_integrations',
  ProIntegrationsApplication = 'pro_integrations_application',
  ProIntegrationsTermsAndConditions = 'pro_integrations_terms_and_conditions',
  DigitalProductPassport = 'digital_product_passport',
  VasComparison = 'vas_comparison',
  FeedbackNew = 'user_leave_feedback',
  SafetyPolicy = 'safety_policy',
  TaxRules = 'taxpayers_tax_rules',
  MoreHomepageItems = 'more_homepage_items',
  ItemVerification = 'item_verification',
  Accessibility = 'accessibility',
  Dac7 = 'dac7',
  NoChangesToTaxes = 'no_changes_to_taxes',
  DsaAppealEducation = 'dsa_appeal_education',
  App = 'app',
  OfflineVerificationFeedbackForm = 'offline_verification_feedback_form',
  BundleDiscountSettings = 'bundle_discount_settings',
  BrandsByLetter = 'brands_by_letter',
  TaxpayerReport = 'taxpayers_report',
  TaxpayerSummary = 'taxpayers_report_history',
  TaxpayerSummaryPlacement = 'taxpayers_report_history',
  AccountStaff = 'account_staff',
  AcceptBusinessInvitation = 'accept_business_invitation',
  ComplaintForm = 'complaint_form',
  TaxpayersSpecialVerificationSuccess = 'taxpayers_special_verification_success',
  ItemBoxMenu = 'itembox_menu',
  Feedback = 'feedback',
  TaxpayersSpecialVerificationEducation = 'taxpayers_special_verification_education',
  TaxpayersSpecialVerificationFailure = 'taxpayers_special_verification_failure',
  TaxpayersSpecialVerificationForm = 'taxpayers_special_verification_form',
  FirstTimeListing = 'first_time_listing',
  DownloadDataExport = 'download_data_export',
  InfluencerLandingPage = 'influencer_landing_page',
  SearchSavedFirstTimeAlert = 'search_saved_first_time_alert',
  ContactSupportFormAddItems = 'contact_support_form_add_items',
  ContactSupportForm = 'contact_support_form',
  ContactSupportFormPreview = 'contact_support_form_preview',
  EstimatedRefundInformation = 'estimated_refund_information',
  SearchItems = 'search_items',
  TaxpayersReportingCenter = 'taxpayers_reporting_center',
  TaxpayersFormStepOne = 'taxpayers_info_submission_form_step1',
  TaxpayersFormStepTwo = 'taxpayers_info_submission_form_step2',
  ShippingDeadlineExtension = 'shipping_deadline_extension',
  OrderList = 'orders_list',
  OrderListSold = 'orders_list_sold',
  OrderListPurchased = 'orders_list_bought',
  MainFooter = 'main_footer',
  AboutMenu = 'about_menu',
  UserMenu = 'user_menu',
  CvvInformation = 'cvv_information',
  CoBrandedSelection = 'co_branded_selection',
  ExternalSurvey = 'external_survey',
  ElectronicsFirstSaleEducation = 'electronics_first_sale_education_screen',
  ElectronicsEvsFirstSaleEducation = 'electronics_evs_first_sale_education_screen',
  ThirdPartyTrackingOptout = 'third_party_tracking_optout',
  SellerInsights = 'seller_insights',
  FirstTimeListerEducation = 'first_time_lister_education',
  LandingPageBuilder = 'landing-page-builder',
  TrendsalesPayoutForm = 'trendsales_payout_form',
  ComplaintResolution = 'complaint_resolution',
  ShippingAddressUpdate = 'shipping_address_update',
  DeletePhoneNumber = 'delete_phone_number',
  PickupPointMapNew = 'pickup_point_map',
  PickupPointListNew = 'pickup_point_list',
  PickupPointInformation = 'pickup_point_info',
  HomeDeliveryOptions = 'home_delivery_options',
  PickupPointSearch = 'pickup_point_search',
  PricingDetails = 'pricing_details',
  SalesTax = 'sales_tax',
  MyOrdersDynamicTab = 'my_orders_dynamic_tab',
  BuyElectronicsLandingPage = 'buy_electronics_landing_page',
  SellElectronicsLandingPage = 'sell_electronics_landing_page',
  HouseOfVintedLandingPage = 'house_of_vinted_landing_page',
  OrdersListLatency = 'orders_list_latency',
  TaxpayerReportsIndividualTrader = 'taxpayers_report_history_c2c',
  ItemPage = 'item_page',
  CompleteRegistration = 'complete_registration',
  ListingRestriction = 'listing_restriction',
  NewAgain = 'new_again',
  BumpWelcomeGift = 'bump_welcome_gift',
}
